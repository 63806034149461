import React from "react"
import Layout from "../Components/Layout"
import Box from "../Components/Box2"

export default () => (
  <Layout>
    <div className="about-page">
      <Box>
        <h2>Privatumo politika</h2>

        <div className="text-container">
          <p>

            <strong style={{lineHeight:"32px"}}>1. Nacionalinio aplinkosaugos egzamino dalyvių privatumo politika</strong><br/>
            Nacionalinį aplinkosaugos egzaminą organizuoja VšĮ „Žaliosios politikos institutas” (toliau – ŽPI). ŽPI yra atsakingas už jūsų informacijos apsaugą ir laikosi Europos Sąjungos Bendrojo duomenų apsaugos reglamento (BDAR) bei kitų jūsų informaciją saugančių įstatymų.

            <br/>
            <br/>
            <strong style={{lineHeight:"32px"}}>2. Kas yra atsakingas už mano informacijos apsaugą?</strong><br/>
            Duomenų valdytojas:<br/>
            VšĮ „Žaliosios politikos institutas”<br/>
            Šermukšnių g. 6A, Vilnius<br/>
            <br/><br/>
            Duomenų tvarkytojai:<br/>
            VšĮ „Žaliosios politikos institutas”, Šermukšnių g. 6A, Vilnius<br/>
            ir<br/>
            Viešoji įstaiga „Lietuvos nacionalinis radijas ir televizija“, Konarskio g. 49, Vilnius.


            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>3. Kodėl renkate informaciją apie mane?</strong><br/>
            Nacionalinio aplinkosaugos egzamino registracijos metu surinkta informacija skirta tam, kad galėtume fiksuoti dalyvių rezultatus ir apie juos informuoti. Jūsų kontaktiniai duomenys taip pat skirti susisiekti su jumis iškilus klausimams ar nesklandumams.
            <br/>
            Jūsų pateikti asmeniniai duomenys nebus naudojami kitais tikslais, nei nurodyta aukščiau.
            Asmuo, nepateikęs registracijai reikalingų duomenų, Nacionaliniame aplinkosaugos egzamine dalyvauti negalės.

            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>4. Kokią informaciją renkate, naudojate ir saugote apie mane?</strong><br/>
              <ul>
                <li>
                  dalyvio vardas, pavardė;
                </li>
                <li>
                  gyvenamoji vieta;
                </li>
                <li>
                  atstovaujama organizacija (mokykla, įmonė ar kt.);
                </li>
                <li>
                  el. paštas;
                </li>
                <li>
                  amžius;
                </li>
                <li>
                  lytis;
                </li>
                <li>
                  išsilavinimas.
                </li>
              </ul>
            <br/>

            <strong style={{lineHeight:"32px"}}>5. Koks yra teisinis informacijos apie mane rinkimo pagrindas?</strong><br/>
            Mes renkame informaciją apie jus teisėtai, nes užsiregistruodami dalyvauti Nacionaliniame aplinkosaugos egzamine sudarote su mumis sutartį (BDAR 6 str. 1 d. b punktas.).
            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>6. Ar renkate jautrią informaciją apie mane?</strong><br/>
            Jautri informacija apie jus nėra renkama ir prašome mums tokios informacijos neteikti. Visi gauti jautrūs duomenys bus nedelsiant ištrinti.
            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>7.  Ar priimate automatinius sprendimus? Ar mane profiliuojate?</strong><br/>
            Automatiniai sprendimai nėra priimami. Jūs nesate profiliuojami.
            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>8. Ar perduodate kam nors informaciją apie mane?</strong><br/>
            Informacija apie jus gali būti perduodama valstybės ar savivaldybės institucijoms bei projekto partneriams ir rėmėjams, kiek tai reikalinga Nacionalinio aplinkosaugos egzamino laimėtojų apdovanojimams. Jaunesnių nei 14 metų egzamino laimėtojų informacija perduodama tik gavus raštišką asmens tėvų arba globėjų sutikimą. <br/>
            Teikėjas viešoji įstaiga „Lietuvos nacionalinis radijas ir televizija“ vykdo duomenų tvarkytojo funkcijas kaip interneto svetainės palaikymo paslaugas teikiantis juridinis asmuo. Jūsų informacija su subjektais, esančiais už Europos ekonominės erdvės ribų, nesidaliname.
            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>9. Kiek laiko saugote informaciją apie mane?</strong><br/>
            Informaciją saugosime vienerius metus nuo jos gavimo.
            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>10. Kokių veiksmų galiu imtis dėl savo informacijos?</strong><br/>
            Jei norite imtis žemiau nurodytų veiksmų, prašome kreiptis į mus, kaip tai nurodyta 11 punkte:<br/>
            <ul>
              <li>
                 pateikti prašymą atskleisti apie jus turimą informaciją;
              </li>
              <li>
                 pateikti prašymą ištaisyti turimą informaciją apie jus;
              </li>
              <li>
                 pateikti prašymą ištrinti turimą informaciją apie jus;
              </li>
              <li>
                 pateikti prašymą apriboti prieigą prie turimos informacijos apie jus ar jos ištrynimą;
              </li>
              <li>
                 ginčyti jūsų informacijos rinkimą, naudojimą ir saugojimą mūsų bendrovėje;
              </li>
              <li>
                 pateikti prašymą eksportuoti jūsų duomenis arba perkelti juos į kitą bendrovę;
              </li>
              <li>
                 pateikti skundą Valstybinei duomenų apsaugos inspekcijai.
              </li>
            </ul>
            Atkreipiame dėmesį, kad šioms jūsų teisėms gali būti taikomos įstatymų numatytos išimtys ir ribojimai.
            <br/>
            <br/>

            <strong style={{lineHeight:"32px"}}>11. Kur galiu kreiptis iškilus daugiau klausimų?</strong><br/>
            Jeigu turite klausimų, pastebėjimų, komentarų ar nusiskundimų su tuo, kaip mes renkame, naudojame ar saugome jūsų duomenis, prašome kreiptis el. paštu: {" "}
            <a href="mailto:info@zaliojipolitika.lt" title="info@zaliojipolitika.lt">
              info@zaliojipolitika.lt
            </a>
            <br/>
            <br/>

          </p>
        </div>
      </Box>
    </div>
  </Layout>
)
